<template>
  <div>
    <div class="report-block__header">
      <div>
        Cluster information
      </div>
    </div>
    <div>
      <InfoBlock
        class="mb-4"
        label="owner"
        :link-text-value="Boolean(clusterData && clusterData.entityId)"
        :loading="loading"
        :value="clusterData
          && clusterData.owner"
        @clickedValue="goToEntity(clusterData && clusterData.entityId)"
      />
      <div
        v-if="clusterData.assumedMeta && clusterData.assumedMeta.length > 0"
        class="mb-4"
      >
        <p class="sidebar__analytics-label">
          Tags
        </p>
        <GlTag
          v-for="(tag, index) in clusterData.assumedMeta"
          :key="index"
          class="mr-1 mb-1"
          :score="tag.score"
          :tag="tag.name"
        />
      </div>
    </div>
    <div class="sankey__info-wrapper">
      <InfoBlock
        class="mb-4"
        label="cluster id"
        :loading="loading"
        :value="clusterData
          && clusterData.cluster"
      />
      <InfoBlock
        class="mb-4"
        label="total sent"
        :loading="loadingOutputs || loadingInputs"
        :rate="clusterData
          && clusterData.totalAmountSentPriceAcc
          && formatByPrice(clusterData.totalAmountSentPriceAcc)"
        rate-time="calculatedAt"
        :rate-timestamp="toTimestamp"
        :value="clusterData
          && clusterData.totalAmountSent
          && toComaSeparate(String(formatBtcAmount(clusterData.totalAmountSent))) || '0'"
      />
      <InfoBlock
        class="mb-4"
        label="Total tx sent"
        :loading="loading"
        :value="clusterData
          && clusterData.totalTxSentCount"
      />
      <InfoBlock
        class="mb-4"
        label="First seen"
        :loading="loading"
        :value="clusterData
          && clusterData.firstSeen
          && formatDate(clusterData.firstSeen * 1000, 'dd.MM.yyyy HH:mm')"
      />
      <InfoBlock
        class="mb-4"
        label="Cluster size"
        :loading="loading"
        :value="clusterData
          && clusterData.addressCount"
      />
      <InfoBlock
        class="mb-4"
        label="Total received"
        :loading="loadingOutputs || loadingInputs"
        :rate="clusterData
          && clusterData.totalAmountReceivedPriceAcc
          && formatByPrice(clusterData.totalAmountReceivedPriceAcc)"
        rate-time="calculatedAt"
        :rate-timestamp="toTimestamp"
        :value="clusterData
          && clusterData.totalAmountReceived
          && toComaSeparate(String(formatBtcAmount(clusterData.totalAmountReceived))) || '0'"
      />
      <InfoBlock
        class="mb-4"
        label="Total tx received"
        :loading="loading"
        :value="clusterData
          && clusterData.totalTxReceivedCount"
      />
      <InfoBlock
        class="mb-4"
        label="Last seen"
        :loading="loading"
        :value="clusterData
          && Number(clusterData.lastSeen)
          && formatDate(clusterData.lastSeen * 1000, 'dd.MM.yyyy HH:mm')"
      />
    </div>
    <!--    <div class="mb-2">-->
    <!--      <div-->
    <!--        class="key mb-2 fs-14"-->
    <!--      >-->
    <!--        Profile Flags-->
    <!--      </div>-->
    <!--      <GlFlagsWrap-->
    <!--        :flags-data="clusterData.flagsData"-->
    <!--        hide-score-->
    <!--        :loading="loading"-->
    <!--        :max-count="2"-->
    <!--      />-->
    <!--    </div>-->
  </div>
</template>

<script>
// Components
import InfoBlock from '@/components/gl-info-block'
import GlTag from "@/components/gl-tag";
import GlFlagsWrap from '@/components/gl-flags-wrap';

// Utils
import { formatDate } from "@/utils/format-date";
import { toComaSeparate } from "@/utils/formatNumber";
import { formatBtcAmount } from "@/utils/format-btc-amount";
import { capitalizeFirstLetter } from "@/utils/text-formatter";
import { formatByPrice } from "@/utils/format-by-price";
import { goToEntity } from "@/utils/go-to-route";

export default {
  components: {
    InfoBlock,
    GlTag,
    GlFlagsWrap
  },
  props: {
    clusterData: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    loadingInputs: {
      type: Boolean,
      default: false
    },
    loadingOutputs: {
      type: Boolean,
      default: false
    },
    toTimestamp: {
      type: Number,
      default: null,
    },
  },
  methods: {
    formatDate,
    toComaSeparate,
    formatBtcAmount,
    capitalizeFirstLetter,
    formatByPrice,
    goToEntity
  }
}
</script>
