<template>
  <div>
    <div class="report-block__header">
      <div>
        Address information
      </div>
    </div>
    <div>
      <InfoBlock
        class="mb-4"
        label="owner"
        :link-text-value="Boolean(addressData && addressData.entityId)"
        :loading="loading"
        :value="addressData
          && (addressData.owner || addressData.clusterOwner)"
        @clickedValue="goToEntity(addressData && addressData.entityId)"
      />
      <div
        v-if="addressData.assumedMeta && addressData.assumedMeta.length > 0"
        class="mb-4"
      >
        <p class="sidebar__analytics-label">
          Tags
        </p>
        <GlTag
          v-for="(tag, index) in addressData.assumedMeta"
          :key="index"
          class="mr-1 mb-1"
          :score="tag.score"
          :tag="capitalizeFirstLetter(tag.name)"
        />
      </div>
    </div>
    <div class="sankey__info-wrapper">
      <InfoBlock
        class="mb-4"
        label="balance"
        :loading="loading"
        :rate="addressData
          && addressData.price
          && formatByPrice(addressData.price)"
        rate-time="calculatedAt"
        :rate-timestamp="toTimestamp"
        :value="addressData
          && addressData.balance
          && toComaSeparate(String(formatBtcAmount(addressData.balance))) || '--'"
      />
      <InfoBlock
        class="mb-4"
        label="total sent"
        :loading="loadingInputs || loadingOutputs"
        :rate="addressData
          && addressData.amountSentPriceAcc
          && formatByPrice(addressData.amountSentPriceAcc)"
        rate-time="calculatedAt"
        :rate-timestamp="toTimestamp"
        :value="addressData
          && addressData.amountSent
          && toComaSeparate(String(formatBtcAmount(addressData.amountSent))) || '0'"
      />
      <InfoBlock
        class="mb-4"
        label="Total tx sent"
        :loading="loading"
        :value="addressData
          && toComaSeparate(String(addressData.txSentCount))"
      />
      <InfoBlock
        class="mb-4"
        label="First seen"
        :loading="loading"
        :value="addressData
          && addressData.firstSeen
          && formatDate(addressData.firstSeen * 1000, 'dd.MM.yyyy HH:mm')"
      />
      <InfoBlock
        class="mb-4"
        label="Number of tXs"
        :loading="loading"
        :value="addressData
          && toComaSeparate(String(addressData.txCount))"
      />
      <InfoBlock
        class="mb-4"
        label="Total received"
        :loading="loadingInputs || loadingOutputs"
        :rate="addressData
          && addressData.amountReceivedPriceAcc
          && formatByPrice(addressData.amountReceivedPriceAcc)"
        rate-time="calculatedAt"
        :rate-timestamp="toTimestamp"
        :value="addressData
          && addressData.amountReceived
          && toComaSeparate(String(formatBtcAmount(addressData.amountReceived))) || '0'"  
      />
      <InfoBlock
        class="mb-4"
        label="Total tx received"
        :loading="loading"
        :value="addressData
          && toComaSeparate(String(addressData.txReceivedCount))"
      />
      <InfoBlock
        class="mb-4"
        label="Last seen"
        :loading="loading"
        :value="addressData
          && addressData.lastSeen
          && formatDate(addressData.lastSeen * 1000, 'dd.MM.yyyy HH:mm')"
      />
    </div>
    <div
      v-if="featureAccess('ALERTS')"
      class="mb-2"
    >
      <div
        class="key mb-2 fs-14"
      >
        Profile Flags
      </div>
      <GlFlagsWrap
        :flags-data="flagsData"
        hide-score
        :loading="loadingFlags"
        :max-count="5"
      />
    </div>
  </div>
</template>

<script>
// Components
import GlTag from "@/components/gl-tag";
import InfoBlock from '@/components/gl-info-block'
import GlFlagsWrap from '@/components/gl-flags-wrap';
// Utils
import { formatDate } from "@/utils/format-date";
import { toComaSeparate } from "@/utils/formatNumber";
import { formatBtcAmount } from "@/utils/format-btc-amount";
import { capitalizeFirstLetter } from "@/utils/text-formatter";
import { featureAccess } from "@/utils/accesses";
import { formatByPrice } from "@/utils/format-by-price";
import { goToEntity } from "@/utils/go-to-route";

export default {
  components: {
    GlTag,
    InfoBlock,
    GlFlagsWrap
  },
  props: {
    addressData: {
      type: Object,
      default: () => ({})
    },
    flagsData: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    loadingFlags: {
      type: Boolean,
      default: false
    },
    loadingInputs: {
      type: Boolean,
      default: false
    },
    loadingOutputs: {
      type: Boolean,
      default: false
    },
    toTimestamp: {
      type: Number,
      default: null,
    },
  },
  methods: {
    formatDate,
    featureAccess,
    toComaSeparate,
    formatBtcAmount,
    capitalizeFirstLetter,
    formatByPrice,
    goToEntity
  }
}
</script>
