var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',[_c('InfoBlock',{staticClass:"mb-4",attrs:{"label":"owner","link-text-value":Boolean(_vm.addressData && _vm.addressData.entityId),"loading":_vm.loading,"value":_vm.addressData
        && (_vm.addressData.owner || _vm.addressData.clusterOwner)},on:{"clickedValue":function($event){return _vm.goToEntity(_vm.addressData && _vm.addressData.entityId)}}}),(_vm.addressData.assumedMeta && _vm.addressData.assumedMeta.length > 0)?_c('div',{staticClass:"mb-4"},[_c('p',{staticClass:"sidebar__analytics-label"},[_vm._v(" Tags ")]),_vm._l((_vm.addressData.assumedMeta),function(tag,index){return _c('GlTag',{key:index,staticClass:"mr-1 mb-1",attrs:{"score":tag.score,"tag":_vm.capitalizeFirstLetter(tag.name)}})})],2):_vm._e()],1),_c('div',{staticClass:"sankey__info-wrapper"},[_c('InfoBlock',{staticClass:"mb-4",attrs:{"label":"balance","loading":_vm.loading,"rate":_vm.addressData
        && _vm.addressData.price
        && _vm.formatByPrice(_vm.addressData.price),"rate-time":"calculatedAt","rate-timestamp":_vm.toTimestamp,"value":_vm.addressData
        && _vm.addressData.balance
        && _vm.toComaSeparate(String(_vm.formatBtcAmount(_vm.addressData.balance))) || '--'}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"label":"total sent","loading":_vm.loadingInputs || _vm.loadingOutputs,"rate":_vm.addressData
        && _vm.addressData.amountSentPriceAcc
        && _vm.formatByPrice(_vm.addressData.amountSentPriceAcc),"rate-time":"calculatedAt","rate-timestamp":_vm.toTimestamp,"value":_vm.addressData
        && _vm.addressData.amountSent
        && _vm.toComaSeparate(String(_vm.formatBtcAmount(_vm.addressData.amountSent))) || '0'}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"label":"Total tx sent","loading":_vm.loading,"value":_vm.addressData
        && _vm.toComaSeparate(String(_vm.addressData.txSentCount))}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"label":"First seen","loading":_vm.loading,"value":_vm.addressData
        && _vm.addressData.firstSeen
        && _vm.formatDate(_vm.addressData.firstSeen * 1000, 'dd.MM.yyyy HH:mm')}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"label":"Number of tXs","loading":_vm.loading,"value":_vm.addressData
        && _vm.toComaSeparate(String(_vm.addressData.txCount))}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"label":"Total received","loading":_vm.loadingInputs || _vm.loadingOutputs,"rate":_vm.addressData
        && _vm.addressData.amountReceivedPriceAcc
        && _vm.formatByPrice(_vm.addressData.amountReceivedPriceAcc),"rate-time":"calculatedAt","rate-timestamp":_vm.toTimestamp,"value":_vm.addressData
        && _vm.addressData.amountReceived
        && _vm.toComaSeparate(String(_vm.formatBtcAmount(_vm.addressData.amountReceived))) || '0'}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"label":"Total tx received","loading":_vm.loading,"value":_vm.addressData
        && _vm.toComaSeparate(String(_vm.addressData.txReceivedCount))}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"label":"Last seen","loading":_vm.loading,"value":_vm.addressData
        && _vm.addressData.lastSeen
        && _vm.formatDate(_vm.addressData.lastSeen * 1000, 'dd.MM.yyyy HH:mm')}})],1),(_vm.featureAccess('ALERTS'))?_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"key mb-2 fs-14"},[_vm._v(" Profile Flags ")]),_c('GlFlagsWrap',{attrs:{"flags-data":_vm.flagsData,"hide-score":"","loading":_vm.loadingFlags,"max-count":5}})],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"report-block__header"},[_c('div',[_vm._v(" Address information ")])])
}]

export { render, staticRenderFns }