var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',[_c('InfoBlock',{staticClass:"mb-4",attrs:{"label":"owner","link-text-value":Boolean(_vm.clusterData && _vm.clusterData.entityId),"loading":_vm.loading,"value":_vm.clusterData
        && _vm.clusterData.owner},on:{"clickedValue":function($event){return _vm.goToEntity(_vm.clusterData && _vm.clusterData.entityId)}}}),(_vm.clusterData.assumedMeta && _vm.clusterData.assumedMeta.length > 0)?_c('div',{staticClass:"mb-4"},[_c('p',{staticClass:"sidebar__analytics-label"},[_vm._v(" Tags ")]),_vm._l((_vm.clusterData.assumedMeta),function(tag,index){return _c('GlTag',{key:index,staticClass:"mr-1 mb-1",attrs:{"score":tag.score,"tag":tag.name}})})],2):_vm._e()],1),_c('div',{staticClass:"sankey__info-wrapper"},[_c('InfoBlock',{staticClass:"mb-4",attrs:{"label":"cluster id","loading":_vm.loading,"value":_vm.clusterData
        && _vm.clusterData.cluster}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"label":"total sent","loading":_vm.loadingOutputs || _vm.loadingInputs,"rate":_vm.clusterData
        && _vm.clusterData.totalAmountSentPriceAcc
        && _vm.formatByPrice(_vm.clusterData.totalAmountSentPriceAcc),"rate-time":"calculatedAt","rate-timestamp":_vm.toTimestamp,"value":_vm.clusterData
        && _vm.clusterData.totalAmountSent
        && _vm.toComaSeparate(String(_vm.formatBtcAmount(_vm.clusterData.totalAmountSent))) || '0'}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"label":"Total tx sent","loading":_vm.loading,"value":_vm.clusterData
        && _vm.clusterData.totalTxSentCount}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"label":"First seen","loading":_vm.loading,"value":_vm.clusterData
        && _vm.clusterData.firstSeen
        && _vm.formatDate(_vm.clusterData.firstSeen * 1000, 'dd.MM.yyyy HH:mm')}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"label":"Cluster size","loading":_vm.loading,"value":_vm.clusterData
        && _vm.clusterData.addressCount}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"label":"Total received","loading":_vm.loadingOutputs || _vm.loadingInputs,"rate":_vm.clusterData
        && _vm.clusterData.totalAmountReceivedPriceAcc
        && _vm.formatByPrice(_vm.clusterData.totalAmountReceivedPriceAcc),"rate-time":"calculatedAt","rate-timestamp":_vm.toTimestamp,"value":_vm.clusterData
        && _vm.clusterData.totalAmountReceived
        && _vm.toComaSeparate(String(_vm.formatBtcAmount(_vm.clusterData.totalAmountReceived))) || '0'}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"label":"Total tx received","loading":_vm.loading,"value":_vm.clusterData
        && _vm.clusterData.totalTxReceivedCount}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"label":"Last seen","loading":_vm.loading,"value":_vm.clusterData
        && Number(_vm.clusterData.lastSeen)
        && _vm.formatDate(_vm.clusterData.lastSeen * 1000, 'dd.MM.yyyy HH:mm')}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"report-block__header"},[_c('div',[_vm._v(" Cluster information ")])])
}]

export { render, staticRenderFns }